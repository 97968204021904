'use client';
import { type PropsWithChildren, useEffect, useState } from 'react';
import { ErrorBoundary, Provider } from '@rollbar/react';
import { getEnv } from '@/utils/env';
import { NODE_ENV } from '@/constants/nodeEnv';

export default function RollbarProvider({ children }: PropsWithChildren) {
  const [config, setConfig] = useState<{
    accessToken: string;
    environment: string;
  }>();

  useEffect(() => {
    setConfig({
      accessToken: '2a03045b8477461b8f1de5fcc25c274d',
      environment: getEnv(),
    });
  }, []);

  return !config || config.environment === NODE_ENV.DEVELOPMENT ? (
    <>{children}</>
  ) : (
    <Provider config={config}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
}
