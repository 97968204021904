import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { UndecoratedProduct } from '@witmetrics/api-client';
import { setActiveAccount } from './activeAccountSlice';

type ProductsState = {
  byID: Record<string, UndecoratedProduct>;
};

const initialState = {
  byID: {},
} satisfies ProductsState as ProductsState;

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<UndecoratedProduct[]>) => {
      return getNextState(state, action.payload);
    },
    addProduct: (state, action: PayloadAction<UndecoratedProduct>) => {
      return getNextState(state, [action.payload]);
    },
    updateProduct: (state, action: PayloadAction<UndecoratedProduct>) => {
      return getNextState(state, [action.payload]);
    },
    deleteProduct: (state, action: PayloadAction<{ productID: number }>) => {
      delete state.byID[`${action.payload.productID}`];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {} };
      })
      .addDefaultCase(() => {});
  },
});

function getNextState(state: ProductsState, products: UndecoratedProduct[]) {
  let byID = { ...state.byID };
  products.forEach((p) => {
    byID[`${p.id}`] = p;
  });
  return { byID };
}

export const { setProducts, addProduct, updateProduct, deleteProduct } =
  productsSlice.actions;

export default productsSlice.reducer;
