import { configureStore } from '@reduxjs/toolkit';
import activeAccountReducer from './slices/activeAccountSlice';
import currentUserReducer from './slices/currentUserSlice';
import notificationsReducer from './slices/notificationsSlice';
import practicePreferencesReducer from './slices/practicePreferencesSlice';
import practicesReducer from './slices/practicesSlice';
import preferencesReducer from './slices/preferencesSlice';
import productsReducer from './slices/productsSlice';
import propertiesReducer from './slices/propertiesSlice';
import unreadNotificationsCountReducer from './slices/unreadNotificationsCountSlice';
import usersReducer from './slices/usersSlice';
import userPracticesSliceReducer from './slices/userPracticesSlice';
import userPreferencesReducer from './slices/userPreferencesSlice';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  reducer: {
    activeAccount: activeAccountReducer,
    currentUser: currentUserReducer,
    notifications: notificationsReducer,
    practicePreferences: practicePreferencesReducer,
    practices: practicesReducer,
    preferences: preferencesReducer,
    products: productsReducer,
    properties: propertiesReducer,
    unreadNotificationsCount: unreadNotificationsCountReducer,
    users: usersReducer,
    userPractices: userPracticesSliceReducer,
    userPreferences: userPreferencesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
