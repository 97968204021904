'use client';
import { type PropsWithChildren, useEffect } from 'react';
import ReduxProvider from '../ReduxProvider';
import SocketsProvider from '../SocketsProvider';
import AppStateProvider from '../AppStateProvider';
import ReportsProvider from '../ReportsProvider';
import { theme } from '@/utils/theme';
import { getEnv } from '@/utils/env';
import { NODE_ENV } from '@/constants/nodeEnv';

export default function AppProviders({ children }: PropsWithChildren) {
  // NOTE: Need to wrap in useEffect otherwise `window` is undefined due to Next.js pre-rendering
  useEffect(() => {
    if (getEnv() !== NODE_ENV.PRODUCTION) window.muiTheme = theme;
  }, []);

  return (
    <ReduxProvider>
      <SocketsProvider>
        <AppStateProvider>
          <ReportsProvider>{children}</ReportsProvider>
        </AppStateProvider>
      </SocketsProvider>
    </ReduxProvider>
  );
}
